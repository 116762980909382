import { Vue, Component } from 'vue-property-decorator'
import WebchatPagePreview from '@userbot/components/dist/u-webchat-page-preview'
import i18n from './i18n'
import { TranslateResult } from 'vue-i18n'

@Component({
  components: {
    WebchatPagePreview
  },
  i18n: {
    messages: {
      i18n
    }
  }
})
export default class ChatPage extends Vue {

  parameters = new URLSearchParams(window.location.search)
  userbot = {
    key: '7c702046f16318d17673aacea6cd707a',
    customer: '6'
  }

  get key (): string {
    return this.parameters.get('key') || this.userbot.key
  }

  get customer (): string {
    return this.parameters.get('customer') || this.userbot.customer
  }

  get title (): string | TranslateResult {
    return this.parameters.get('title') || this.$t('title') || ''
  }

  get description (): string | TranslateResult {
    return this.parameters.get('payoff') || this.$t('description') || ''
  }

  get backButtonText (): string | boolean {
    if (this.parameters.get('back') !== 'false') {
      return this.parameters.get('back-text') || this.$t('back').toString()
    }
    return false
  }

  get avatar (): any {
    if ((this.key === this.userbot.key && this.customer === this.userbot.customer) || this.parameters.get('avatar')) {
      return {
        "name": this.parameters.get('avatar-name') || '',
        "placeholder": this.parameters.get('avatar-placeholder') || 'https://cdn.userbot.ai/video/laura/placeholder.jpg',
        "waitingVideoSource": this.parameters.get('avatar-waiting-video') || 'https://cdn.userbot.ai/video/laura/waiting.mp4',
        "type": 'video',
        "enabled": true,
        "opened": true,
        "mobile": false
      }
    } else {
      return {
        "enabled": false,
      }
    }
  }
  created (): void {
    const recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', "https://cdn.userbot.ai/widget-chat/dist/userbot.js")
    document.head.appendChild(recaptchaScript)
    recaptchaScript.addEventListener('load', this.init)
  }

  init (): void {
    document.addEventListener('userbot.ready', () => {

      // @ts-ignore
      window.Userbot({
        "key": this.key,
        "customerToken": this.customer,
        "app":{
          "hostname":'https://cdn.userbot.ai/widget-chat/dist',
          "socket":"ai.userbot.ai"
        },
        "avatar": this.avatar
      });
    })
  }

  test (): void {
    // @ts-ignore
    if (window.Userbot()) {
      // @ts-ignore
      window.Userbot().open()
    }
  }
}
