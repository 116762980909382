export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome on your Bot test page"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on Widget Web Chat or the button below to start."])},
        "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Web chat"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to dashboard"])}
      },
      "it": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome on your Bot test page"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on Widget Web Chat or the button below to start."])},
        "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Web chat"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to dashboard"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome on your Bot test page"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on Widget Web Chat or the button below to start."])},
        "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Web chat"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Back to dashboard"])}
      }
    }
  })
}
