






import { Component, Vue } from 'vue-property-decorator';
import ChatPage from '../src/components/chat-page/index.vue'

@Component({
  components: {
    ChatPage

  },
})
export default class App extends Vue { }
