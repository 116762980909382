import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export default new VueI18n({
  locale: navigator.language.split('-')[0],
  fallbackLocale: 'en',
  messages: {
    "en": {
      "title": "Test Userbot's AI in action",
      "description": "Write in chat and find out what Userbot can do",
      "buttonText": "Open Web chat",
      "back": "Come back"
    },
    "it": {
      "title": "Prova l’AI di Userbot in azione",
      "description": "Scrivi in chat e scopri cosa può fare Userbot",
      "buttonText": "Apri la chat di test",
      "back": "Torna indietro"
    },
    "fr": {
      "title": "Découvrez Userbot AI en action",
      "description": "Écrivez dans le chat et découvrez ce que Userbot peut faire",
      "buttonText": "Ouvrir le chat Web",
      "back": "Revenir"
    }
  }
})
