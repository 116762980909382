export default {
  "en": {
    "title": "Welcome on your Bot test page",
    "description": "Click on Widget Web Chat or the button below to start.",
    "buttonText": "Open Web chat",
    "back": "Back to dashboard"
  },
  "it": {
    "title": "Welcome on your Bot test page",
    "description": "Click on Widget Web Chat or the button below to start.",
    "buttonText": "Open Web chat",
    "back": "Back to dashboard"
  },
  "fr": {
    "title": "Welcome on your Bot test page",
    "description": "Click on Widget Web Chat or the button below to start.",
    "buttonText": "Open Web chat",
    "back": "Back to dashboard"
  }
}